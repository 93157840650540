<template>
  <login-layout>
    <div class="panel">
      <h2>Aanmelden</h2>
    </div>
    <div>
      <form @submit.prevent="handleSubmit">
        <div v-if="alertMessage" :class="`alert ${alertType}`">{{ alertMessage }}</div>

        <div class="form-group">
          <input v-model="username" type="email" class="form-control" placeholder="E-mail adres" />
        </div>

        <div class="form-group">
          <input v-model="password" type="password" class="form-control" placeholder="Wachtwoord" />
        </div>
        <div class="forgot">
          <router-link :to="{ name: 'users-forgot-password' }"> Wachtwoord vergeten? </router-link>
        </div>
        <button type="submit" class="btn btn-success">Login <i class="far fa-arrow-alt-circle-right" /></button>
      </form>
    </div>
  </login-layout>
</template>

<style lang="scss" scoped>
.forgot {
  text-align: left;
  margin-bottom: 30px;

  a {
    color: #777777;
    font-size: 14px;
    text-decoration: underline;
  }
}
</style>

<script>
//import router from "@/router";
import { mapActions, mapState } from "vuex";
import LoginLayout from "@/components/authentication/LoginLayout.vue";

export default {
  components: { LoginLayout },

  data() {
    return {
      username: "",
      password: ""
    };
  },

  computed: {
    ...mapState("Alert", { alertType: "type", alertMessage: "message" })
  },

  created() {
    this.logout();
  },

  methods: {
    ...mapActions("Authentication", ["login", "logout"]),

    handleSubmit: function () {
      const { username, password } = this;

      if (username && password) {
        this.login({ username, password });

        //router.push("/");
      }
    }
  }
};
</script>
